.hubspot-form .field {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-bottom: 1rem;
}

.hubspot-form .field label {
    color: var(--color-text-primary);
    text-align: left;

    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 10px;
    font-weight: 500;
}

.hubspot-form .field .hs-form-required {
    color: #22c55e;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-left: 0.25rem;
}

.hubspot-form .input {
    display: flex;
    align-items: center;
    height: 100%;
    max-height: 38px;
    border-radius: 0.375rem;
    border: 1px solid var(--color-border-primary);
    overflow: hidden;
}
.hubspot-form .input:hover {
    border-color: var(--color-border-primary-blue);
}

.hubspot-form input {
    color: var(--color-text-primary);
    font-size: 12px;
    background-color: var(--color-bg-primary);
    padding: 0.75rem;
    width: 100%;
    height: 100%;
    max-height: 38px;
}
.hubspot-form input::placeholder {
    opacity: 0;
}

.hubspot-form .hs-error-msgs > li {
    text-align: left;
}

.hubspot-form .hs-error-msg {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    color: #f87171 !important;
    font-weight: normal !important;
}

.hubspot-form .form-columns-2 {
    max-width: unset !important;
    margin-right: -8px;
}

.hubspot-form .hs_error_rollup {
    display: none;
}


/* Dropdown styling */
.hubspot-form select {
    appearance: none;
    background-color: var(--color-bg-primary);
    color: var(--color-text-primary);
    font-size: 14px;
    border-radius: 6px;
    padding: 8px 12px;
    width: 100%;
    cursor: pointer;
}

.hubspot-form select:focus {
    outline: none;
    border-color: #3b82f6;
}

/*.custom-select-container {*/
/*    position: relative;*/
/*    display: inline-block;*/
/*    width: 200px;*/
/*}*/

/*.custom-select-container::after {*/
/*    content: '▼';*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    right: 12px;*/
/*    transform: translateY(-50%);*/
/*    pointer-events: none;*/
/*    color: var(--color-text-primary);*/
/*}*/

.hubspot-form select option {
    background-color: var(--color-bg-primary);;
    color: var(--color-text-primary);
    padding: 10px;
}

.hubspot-form select::-ms-expand {
    display: none; /* Remove default arrow in IE */
}


.hubspot-form .hs-button {
    background-color: var(--color-bg-primary-blue);
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
}

.hubspot-form .hs-button:hover {
    background-color: rgb(51 131 176);
}

#hubspot-form .submitted-message {
    color: var(--color-text-primary);
}